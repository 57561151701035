import { MainComponent } from './main/main.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { CustomerdetailComponent } from './customer/customerdetail/customerdetail.component';
import { EditAccountComponent } from './account/edit-account/edit-account.component';
import { AccountloginComponentComponent } from './accountlogin-component/accountlogin-component.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { AuthGuardServiceService } from './auth-guard-service.service';
import { CouponListComponent } from './coupon/coupon-list/coupon-list.component';
import { AddCouponsComponent } from './coupon/add-coupons/add-coupons.component';
import { VendorTransactionListComponent } from './vendor-finance/transaction-list/transaction-list.component';
import { VendorTransactionDetailsComponent } from './vendor-finance/transaction-details/transaction-details.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ValidatepasswordComponent } from './validatepassword/validatepassword.component';

const routes: Routes = [
  {
    path: 'vendor',
    canActivate: [AuthGuardServiceService],
    component: MainComponent,
    children: [
      // customers
      { path: 'customers', component: CustomerListComponent },
      { path: 'customers/customer/:id', component: CustomerdetailComponent },

      // couopn
      { path: 'coupons', component: CouponListComponent },
      { path: 'coupons/coupon', component: AddCouponsComponent },
      { path: 'coupons/coupon/:id', component: AddCouponsComponent },

      // transaction
      { path: 'transactions', component: VendorTransactionListComponent },
      { path: 'transactions/transaction/:email/:id', component: VendorTransactionDetailsComponent },

      // Edit Account
      { path: 'account', component: EditAccountComponent },
    ]
  },
  //login
  { path: '', component: AccountloginComponentComponent },
  { path: 'login', component: AccountloginComponentComponent },
  { path: 'forget', component: ForgetpasswordComponent },
  { path: 'reset/:email/:randNo', component: ResetpasswordComponent },
  { path: 'validate/:id/:random', component: ValidatepasswordComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
