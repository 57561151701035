import { AddEditData, UtilityService } from "./../../utility.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";
import { ApiDataService } from "src/app/api-data.service";
import { Location } from '@angular/common';

@Component({
  selector: "app-transaction-details",
  templateUrl: "./transaction-details.component.html",
  styleUrls: ["./transaction-details.component.css"],
})
export class VendorTransactionDetailsComponent implements OnInit, AddEditData {
  // step 1 : Assign section variable correctly
  // Step 2 : If EditMode validate and Bind Html input to this object using template driven forms (2-way Binding ([ngModel]))
  // Step 3 : If any Binary Data is uploaded add it to Binary Array
  // Step 4 : on Submit validate schema to be sent-->if valid upload Binary Array to S3 ,upon succes upload Form Data
  // Step 6 : Reset State of form after submit
  // CamelCase for naming anything

  // select this appropriately
  section = this.utility.apiData.purchases;
  object: any;
  isEditMode = false;
  isUploadingData = false;
  isLoadingData = false;
  binaryFiles = [];

  constructor(
    private route: ActivatedRoute,
    private utility: UtilityService,
    private dataService: ApiDataService,
    private location: Location
  ) { }

  @ViewChild("mainForm", { static: false }) mainForm: NgForm;

  ngOnInit() {
    this.resetForm();
    this.hasData();
  }

  resetForm() {
    this.isUploadingData = false;
    this.isLoadingData = false;
    this.isEditMode = false;
    // change
    this.object = JSON.parse(JSON.stringify(this.section.object));
  }

  loadData(id) { }

  loadDatFinance(email, id) {
    //Get Form Data via API
    this.dataService.getDataFinance(this.section.ApiUrl, email, id).subscribe(
      (Response) => {
        console.log(this.utility.dovValidateSchema.validate(Response, this.section.schema))
        if (!this.utility.dovValidateSchema.validate(Response, this.section.schema).valid) {
          swal("No data exists");
          this.location.back();
        }
        this.object = Response;
        this.isLoadingData = false;
      },
      (error) => {
        swal("No data exists");
        this.location.back();
      });
  }

  hasData() {
    this.route.paramMap.subscribe((params) => {
      if (params.get("id") && params.get("id") != "" && params.get("email") && params.get("email") != "") {
        this.isLoadingData = true;
        this.isEditMode = true;
        this.loadDatFinance(params.get("email"), params.get("id"));
      }
    });
  }

  uploadBinaryData() {
    this.object.imageSrc = ""; //only in cases where there is singular binding
    let requests = this.binaryFiles.map((object) => {
      return this.utility.uploadBinaryData(object["name"], object["binaryData"], this.section.bucket);
    });
    Promise.all(requests)
      .then((responses) => {
        this.object.imageSrc = responses[0]["name"];
        this.uploadFormData();
      })
      .catch((error) => {
        console.log(error);
        this.isUploadingData = false;
      });
  }

  uploadFormData() {
    //post request here,both add & update are sent as post
    this.dataService
      .postData(this.section.ApiUrl, JSON.stringify(this.object))
      .subscribe((Response) => {
        console.log(Response);
        this.isEditMode ? swal("Vendor updated succesfully") : swal("Vendor added succesfully");
        this.location.back();
      }, (error) => {
        if (error.status == 406)
          swal("Failed to add vendor,E-Mail Address exists");
        else
          swal("Failed to process request,please try again");
        console.log(error);
        this.isUploadingData = false;
      });
  }

  loadBinaryFile(event) {
    if (event.target.files.length > 0) {
      //reset binaryFiles array to this image --> S3 allows to directly upload file object or Blob data,for simplicity here file object is used
      this.binaryFiles = [
        { name: uuidv4(), binaryData: event.target.files[0] },
      ];

      //display selected file in image tag
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); //initiates converting file to blob
      // reader.onload = (e) => (this.imageSrc = reader.result); // call back after file is converted to Blob
    }
  }

  onSubmit() { }

  ClaimCommision() {
    this.isUploadingData = true;
    let json = {};
    json['status'] = false;
    json['vendorID'] = JSON.parse(sessionStorage.getItem('vendorUser'))['vendorID'];
    json['customerInvoiceId'] = this.object.customerInvoiceId;
    json['price'] = 0;
    json['taxDeductions'] = 0;
    json['totalPrice'] = 0;

    this.dataService
      .postData(this.utility.apiData.vendorClaims.ApiUrl, JSON.stringify(json))
      .subscribe((Response) => {
        console.log(Response);
        swal("Claim Raised succesfully")
        this.location.back();
      }, (error) => {
        swal("Failed to process request,please try again");
        console.log(error);
        this.isUploadingData = false;
      });
    //here the vendor is claiming for commision --> this should reflect to admin--> No E-Mail for the time being
  }
}
