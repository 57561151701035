import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { ApiDataService } from '../api-data.service';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {
  sending: boolean;

  constructor(private utility: UtilityService, private router: Router, private Service: ApiDataService) { }

  emailSent = false;
  email = null;

  ngOnInit() {
    this.emailSent = false;
    this.sending = false;
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      form.form.markAllAsTouched();
      return;
    }
    this.sending = true;
    let json: JSON = form.value;
    json['isForget'] = true;
    json['url'] = this.utility.passwordResetURL;
    this.Service.postData(this.utility.apiData.vendorAccounts.ApiUrl, json)
      .subscribe(Response => {
        this.sending = false;
        this.emailSent = true;
        this.email = json['email'];
      }, error => {
        this.sending = false;
        swal("Unable to reset password,please try again");
        switch (error.status) {
          case 400: {
            swal("Bad request,enter proper Email address")
            break;
          }
          case 404: {
            swal("E-Mail ID does not exists,please signup to continue")
            break;
          }
          case 500: {
            swal("Unable to reset password,please try again");
            break;
          }
        }
      });
  }
}

