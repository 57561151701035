import { ListData, UtilityService } from "./../../utility.service";
import { ApiDataService } from "./../../api-data.service";
import { Component, OnInit } from "@angular/core";
import sortArray from 'sort-array';

@Component({
  selector: "app-customer-list",
  templateUrl: "./customer-list.component.html",
  styleUrls: ["./customer-list.component.css"],
})
export class CustomerListComponent implements OnInit, ListData {
  constructor(
    private dataService: ApiDataService,
    private utility: UtilityService
  ) { }

  isLoadingData = false;
  objectList: any;
  pristineData: any;
  itemsPerPage = 10;
  pageNumber = 0;
  // select this appropriately
  object = this.utility.apiData.accounts;
  vendor = JSON.parse(sessionStorage.getItem('vendorUser'));

  loadData() {
    //Get Form Data via API
    console.log(this.vendor);
    this.dataService.getallData(this.object.ApiUrl + `?vendorid=` + this.vendor['vendorID'])
      .subscribe(Response => {
        this.objectList = Response;
        this.pristineData = Response;
        this.isLoadingData = false;
      }, error => {
        this.isLoadingData = false;
        console.log(error);
      });
  }

  ngOnInit() {
    this.isLoadingData = true;
    this.objectList = [];
    this.loadData();
  }

  filterData(filterValue: string) {
    this.objectList = this.pristineData.filter((accounts) => {
      if (accounts.accountfirstName || accounts.accountlastName || accounts.emailAddress)
        return (
          accounts.accountfirstName.toLowerCase().includes(filterValue) || accounts.accountlastName.toLowerCase().includes(filterValue) || accounts.emailAddress.toLowerCase().includes(filterValue)
        );
    });
  }

  sortBoolean: any = {};
  sortData(sortValue) {
    this.sortBoolean[sortValue] = this.sortBoolean[sortValue] ? false : true;
    sortArray(this.objectList, { by: sortValue, order: this.sortBoolean[sortValue] ? 'desc' : 'asc' });
  }

  counter(items: number) {
    return new Array(Math.round(items / this.itemsPerPage));
  }

  // helper function
  changePage(number: number) {
    this.pageNumber = number * this.itemsPerPage;
  }

  isActive(customer) {
    if (!('status' in customer)) return true;
    return customer.status;
  }
}



