import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert';
import { v4 as uuidv4 } from 'uuid';
import { ApiDataService } from '../api-data.service';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-accountlogin-component',
  templateUrl: './accountlogin-component.component.html',
  styleUrls: ['./accountlogin-component.component.css']
})
export class AccountloginComponentComponent implements OnInit {
  sending: boolean;

  constructor(private route: Router,
    private utility: UtilityService,
    private dataService: ApiDataService) { }

  ngOnInit() {
    sessionStorage.removeItem("vendorUser");
    this.sending = false;
  };

  onSubmit(form: NgForm) {
    if (form.invalid) {
      form.form.markAllAsTouched();
      return;
    }
    this.sending = true;
    const json: JSON = form.value;
    json['isLogin'] = true;
    this.dataService.postData(this.utility.apiData.vendorAccounts.ApiUrl, json)
      .subscribe(Response => {
        this.sending = false;
        this.route.navigate(['vendor/customers'])
        sessionStorage.setItem("vendorUser", JSON.stringify(Response));
      }, (error) => {
        this.sending = false;
        if (error.status === 404)
          swal('E-Mail ID does not exists,please signup to continue');
        if (error.status === 403)
          swal('Account Disabled,contact Dental Live');
        else if (error.status === 400)
          swal('Wrong Password,please try again');
        else if (error.status === 401)
          swal('Account not verified, please verify your account');
        else
          swal('Unable to login, please try again');
      });
  };
}

