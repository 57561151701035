import { AddEditData, UtilityService } from "./../../utility.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";
import { ApiDataService } from "src/app/api-data.service";
import { Location } from '@angular/common';

@Component({
  selector: "app-customerdetail",
  templateUrl: "./customerdetail.component.html",
  styleUrls: ["./customerdetail.component.css"],
})
export class CustomerdetailComponent implements OnInit, AddEditData {
  objectAllList: Object;
  pristineALLData: Object;
  subUsers: any;
  subscriptions: any;

  constructor(
    private route: ActivatedRoute,
    private utility: UtilityService,
    private dataService: ApiDataService,
    private location: Location
  ) { }

  // step 1 : Assign section variable correctly
  // Step 2 : If EditMode validate and Bind Html input to this object using template driven forms (2-way Binding ([ngModel]))
  // Step 3 : If any Binary Data is uploaded add it to Binary Array
  // Step 4 : on Submit validate schema to be sent-->if valid upload Binary Array to S3 ,upon succes upload Form Data
  // Step 6 : Reset State of form after submit
  // CamelCase for naming anything

  // select this appropriately
  section = this.utility.apiData.accounts;
  imageSrc: any;
  object: any;
  isEditMode = false;
  isUploadingData = false;
  isLoadingData = false;
  binaryFiles = [];

  statusExists = false;

  @ViewChild("mainForm", { static: false }) mainForm: NgForm;

  ngOnInit() {
    this.resetForm();
    this.hasData();
  }

  resetForm() {
    this.isUploadingData = false;
    this.isLoadingData = false;
    this.isEditMode = false;
    // change
    this.object = JSON.parse(JSON.stringify(this.section.object));
    this.imageSrc = null;
  }

  loadData(id) {
    console.log(this.object.dependencies.purchases);
    //Get Form Data via API
    this.dataService.getData(this.section.ApiUrl, id).subscribe(
      (Response) => {
        console.log(Response);
        if (!this.utility.dovValidateSchema.validate(Response, this.section.schema).valid) {
          swal("No data exists");
          this.location.back();
        }
        this.object = Response;
        if (('status' in this.object)) this.statusExists = true;
        this.isLoadingData = false;
      },
      (error) => {
        console.log(error);
        swal("No data exists");
        this.location.back();
      });
  }

  hasData() {
    this.route.paramMap.subscribe((params) => {
      if (params.get("id") && params.get("id") != "") {
        this.isLoadingData = true;
        this.isEditMode = true;
        this.loadData(params.get("id"));
      }
    });
  }

  uploadBinaryData() {

  }

  uploadFormData() {

  }

  loadBinaryFile(event) {

  }

  onSubmit() {

  }

}