import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(private utility: UtilityService) { }
  user = {}

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("vendorUser"));
  }

}
