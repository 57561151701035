import { AddEditData, UtilityService } from "./../../utility.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";
import { ApiDataService } from "src/app/api-data.service";
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.css']
})
export class EditAccountComponent implements OnInit, AddEditData {
  // step 1 : Assign section variable correctly
  // Step 2 : If EditMode validate and Bind Html input to this object using template driven forms (2-way Binding ([ngModel]))
  // Step 3 : If any Binary Data is uploaded add it to Binary Array
  // Step 4 : on Submit validate schema to be sent-->if valid upload Binary Array to S3 ,upon succes upload Form Data
  // Step 6 : Reset State of form after submit
  // CamelCase for naming anything

  // select this appropriately
  section = this.utility.apiData.vendorAccounts;
  imageSrc: any;
  object: any;
  isEditMode = false;
  isUploadingData = false;
  isLoadingData = false;
  binaryFiles = [];

  constructor(
    private route: ActivatedRoute,
    private utility: UtilityService,
    private dataService: ApiDataService,
    private location: Location
  ) { }

  vendorID = JSON.parse(sessionStorage.getItem("vendorUser")).vendorID;

  @ViewChild("mainForm", { static: false }) mainForm: NgForm;
  @ViewChild("subForm", { static: false }) subForm: NgForm;

  ngOnInit() {
    this.resetForm();
    this.hasData();
  }

  resetForm() {
    this.isUploadingData = false;
    this.isLoadingData = false;
    this.isEditMode = false;
    // change
    this.object = JSON.parse(JSON.stringify(this.section.object));
    this.imageSrc = null;
  }

  loadData(id) {
    //Get Form Data via API
    this.dataService.getData(this.section.ApiUrl, id).subscribe(
      (Response) => {
        console.log(this.utility.dovValidateSchema.validate(Response, this.section.schema));
        if (!this.utility.dovValidateSchema.validate(Response, this.section.schema).valid) {
          swal("No data exists");
          this.location.back();
        }
        this.object = Response;
        if (this.object.imageSrc) this.imageSrc = this.section.bucketUrl + this.object.imageSrc;
        this.isLoadingData = false;
      },
      (error) => {
        swal("No data exists");
        this.location.back();
      });
  }

  hasData() {
    if (this.vendorID) {
      this.isLoadingData = true;
      this.isEditMode = true;
      this.loadData(this.vendorID);
    } else {
      swal("Invalid user selected");
      this.location.back();
    }
  }

  uploadBinaryData() {
    this.object.imageSrc = ""; //only in cases where there is singular binding
    let requests = this.binaryFiles.map((object) => {
      return this.utility.uploadBinaryData(object["name"], object["binaryData"], this.section.bucket);
    });
    Promise.all(requests)
      .then((responses) => {
        this.object.imageSrc = responses[0]["name"];
        this.uploadFormData();
      })
      .catch((error) => {
        console.log(error);
        this.isUploadingData = false;
      });
  }

  uploadFormData() {
    //post request here,both add & update are sent as post
    this.dataService
      .postData(this.section.ApiUrl, JSON.stringify(this.object))
      .subscribe((Response) => {
        sessionStorage.setItem("vendorUser", JSON.stringify(Response));
        this.isUploadingData = false;
        swal("Updated Succesfully");
      }, (error) => {
        swal("Failed to process request,please try again");
        console.log(error);
        this.isUploadingData = false;
      });
  }

  loadBinaryFile(event) {
    if (event.target.files.length > 0) {
      //reset binaryFiles array to this image --> S3 allows to directly upload file object or Blob data,for simplicity here file object is used
      this.binaryFiles = [
        { name: uuidv4(), binaryData: event.target.files[0] },
      ];

      //display selected file in image tag
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); //initiates converting file to blob
      reader.onload = (e) => (this.imageSrc = reader.result); // call back after file is converted to Blob
    }
  }

  onSubmit() {
    if (this.mainForm.invalid || this.object.assignedPackages.length == 0) {
      this.mainForm.form.markAllAsTouched();
      swal("Please enter values in the highlighted fields");
      return false;
    }
    this.isUploadingData = true;
    console.log(this.object);
    if (this.binaryFiles.length > 0) {
      this.uploadBinaryData();
    } else {
      this.uploadFormData();
    }
  }
  updatePassword(npass, opass, cpass) {
    if (this.subForm.invalid || (npass != cpass)) {
      this.mainForm.form.markAllAsTouched();
      swal("Please enter values in the highlighted fields");
      return false;
    }
    this.isUploadingData = true;
    let obj = {
      "vendorID": this.vendorID,
      "password": npass,
      "oldPassword": opass
    }
    this.dataService
      .putData(this.section.ApiUrl, JSON.stringify(obj))
      .subscribe((Response) => {
        console.log(Response);
        swal("Account updated succesfully");
        this.subForm.reset();
        this.isUploadingData = false;
      }, (error) => {
        swal("Failed to process request,please try again");
        console.log(error);
        this.isUploadingData = false;
      });
  }
}
